import { Auth } from "../Api/Auth";
import { Persistence } from "./Persistence";

class CredentialsStore {
    public bearerToken: string;
    public isAuthenticated: boolean;
    public id: number;
    public admin: number;

    constructor() {
        const token = Persistence.getValue("token");

        if (token) {
            this.bearerToken = token;
            this.isAuthenticated = true;
            this.id = +Persistence.getValue("id");
            this.admin = +Persistence.getValue("admin");
        } else {
            this.bearerToken = "";
            this.isAuthenticated = false;
            this.id = 0;
            this.admin = 0;
        }
    }

    public LogIn = async (username: string, password: string): Promise<boolean> => {
        const auth = await Auth.SignIn(username, password);

        if (auth.isAuthenticated) {
            Persistence.setValue("token", auth.token);
            Persistence.setValue("id", auth.id.toString());
            Persistence.setValue("admin", auth.admin.toString());
            this.isAuthenticated = true;
            this.bearerToken = auth.token;

            return true;
        } else {
            return false;
        }
    };

    public LogOut = () => {
        this.isAuthenticated = false;
        this.bearerToken = "";
        Persistence.setValue("token", "");
        Persistence.setValue("id", "");
        Persistence.setValue("admin", "");
        window.location.replace("/");
    };
}

export const Credentials = new CredentialsStore();
