class PersistenceStore {
    public getValue = (key: string): string => {
        return localStorage.getItem(key) || "";
    };

    public setValue = (key: string, value: string) => {
        return localStorage.setItem(key, value);
    };
}

export const Persistence = new PersistenceStore();
