import { useCallback, useState, useEffect, useMemo } from "react";
import Page from "../../Component/Page/Page";
import styles from "./store.module.scss";
import { StoryArt, storyApiInstance, StoryAffiliate } from "../../Api/Story";
import Affiliate from "../../Component/affiliate";
import Picture from "./picture";
import { ProductOption, TeemillApiInstance } from "../../Api/Teemill";
import Select from "react-select";
import classNames from "classnames";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRotateRight, faSpinner } from "@fortawesome/free-solid-svg-icons";
import { selectStyles } from "../../App";

export type TOption = {
    value: string;
    label: React.ReactNode;
};

const STORE = () => {
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [list, setList] = useState<(StoryArt | StoryAffiliate)[]>([]);
    const [country, setCountry] = useState<string>("");
    const [products, setProducts] = useState<ProductOption[]>([]);
    const [selectedProductValue, setSelectedProductValue] = useState<string | null>();
    const [selectedProduct, setSelectedProduct] = useState<TOption | null>(null);
    const [selectedProductColorValue, setSelectedProductColorValue] = useState<string | null>();
    const [selectedProductColor, setSelectedProductColor] = useState<TOption | null>(null);
    const [selectedProductColorImageUrl, setSelectedProductColorImageUrl] = useState<string>("");
    const [isCreatingProduct, setIsCreatingProduct] = useState<boolean>(false);
    const [productLink, setProductLink] = useState<string>("");
    const [mode, setMode] = useState<"featured" | "latest" | "random">("random");
    const [page, setPage] = useState<number>(1);
    const [totalpages, setTotalpages] = useState<number>(1);

    const productOptions: TOption[] = useMemo(
        () =>
            products.map((product) => {
                return {
                    value: product.item_code,
                    label: product.name
                };
            }),
        [products]
    );

    const colorOptions: TOption[] = useMemo(
        () =>
            products
                .find((product) => product.item_code === selectedProductValue)
                ?.colours.map((colour) => {
                    return {
                        value: colour.name,
                        label: colour.name
                    };
                }) || [],
        [products, selectedProductValue]
    );

    useEffect(() => {
        let selected: TOption | null = null;

        if (selectedProductValue) {
            if (productOptions) {
                selected =
                    productOptions.find(
                        (option: TOption) => selectedProductValue === option.value
                    ) || null;
            }
        }

        setSelectedProduct(selected);
    }, [productOptions, selectedProductValue]);

    useEffect(() => {
        let selected: TOption | null = null;

        if (selectedProductColorValue) {
            if (colorOptions) {
                selected =
                    colorOptions.find(
                        (option: TOption) => selectedProductColorValue === option.value
                    ) || null;
            }
        }

        setSelectedProductColor(selected);
    }, [colorOptions, selectedProductColorValue]);

    const productColorImageUrl = useMemo(() => {
        if (products && selectedProductValue && selectedProductColorValue) {
            const product = products.find((product) => product.item_code === selectedProductValue);

            if (product) {
                const colour = product.colours.find(
                    (colour) => colour.name === selectedProductColorValue
                );

                if (colour) {
                    return colour.image;
                }
            }
        }

        return "";
    }, [products, selectedProductValue, selectedProductColorValue]);

    const loadList = useCallback(async () => {
        setIsLoading(true);

        let data: any;

        if (mode === "featured") {
            data = {
                list: [
                    {
                        token: "featured-0005"
                    },
                    {
                        token: "featured-0006"
                    },
                    {
                        token: "featured-0004"
                    },
                    {
                        token: "featured-0001"
                    },
                    {
                        token: "featured-0002"
                    },
                    {
                        token: "featured-0003"
                    }
                ],
                quantity: 6
            };
            setPage(2);
            setTotalpages(1);
        } else if (mode === "latest") {
            data = await storyApiInstance.ListArt(page);
            setPage(page + 1);
            setTotalpages(Math.ceil(data.quantity / 6));
        } else {
            data = await storyApiInstance.ListRandomArt(6);
            setPage(page + 1);
            setTotalpages(Math.ceil(data.quantity / 6));
        }

        //const affiliateData = await storyApiInstance.getAffiliate();

        if (data) {
            //setList([...list, ...data.list, ...affiliateData.list]);
            setList([...list, ...data.list]);
        }

        //setCountry(affiliateData.country);

        setIsLoading(false);
    }, [list, page, mode]);

    const handleScroll = useCallback(() => {
        if (
            Math.ceil(window.innerHeight + window.scrollY) <
                document.documentElement.scrollHeight - 100 ||
            page > totalpages ||
            isLoading
        ) {
            return;
        }
        loadList();
    }, [isLoading, loadList, page, totalpages]);

    useEffect(() => {
        window.addEventListener("scroll", handleScroll);
        return () => window.removeEventListener("scroll", handleScroll);
    }, [handleScroll, isLoading]);

    useEffect(() => {
        const loadOptions = async () => {
            const productData = await TeemillApiInstance.getOptions();

            setProducts(productData);

            const selectedProduct = productData.find((product) => product.item_code === "RNA1");

            if (selectedProduct) {
                setSelectedProductValue(selectedProduct.item_code);
                setSelectedProductColorValue(selectedProduct.colours[0].name);
            }
        };

        loadOptions();
    }, []);

    const handleModeChange = useCallback((mode: string) => {
        setMode(mode as "featured" | "latest" | "random");
        setList([]);
        setPage(1);

        setTimeout(() => {
            var event = new Event("scroll");
            window.dispatchEvent(event);
        }, 100);
    }, []);

    useEffect(() => {
        loadList();
    }, []);

    return (
        <Page showNav={true} isLoading={isLoading} hideContentDuringLoading={false}>
            <div className={styles["store"]}>
                {productOptions.length > 0 && (
                    <div className={styles["wrapper"]}>
                        <div className={styles["product-filter"]}>
                            <img
                                className={styles["filter-image"]}
                                src={productColorImageUrl}
                                alt={""}
                            />
                            <div className={styles["product-options"]}>
                                <Select
                                    options={productOptions}
                                    value={selectedProduct}
                                    onChange={(value) => {
                                        //@ts-ignore
                                        setSelectedProductValue(value?.value || null);
                                        setSelectedProductColorValue(
                                            products.find(
                                                //@ts-ignore
                                                (product) => product.item_code === value?.value
                                            )?.colours[0].name
                                        );
                                    }}
                                    styles={selectStyles}
                                />
                                <Select
                                    options={colorOptions}
                                    value={selectedProductColor}
                                    onChange={(value) => {
                                        //@ts-ignore
                                        setSelectedProductColorValue(value?.value || null);
                                    }}
                                    styles={selectStyles}
                                />
                            </div>
                        </div>

                        <div className={styles["mode-filter"]}>
                            <div
                                className={classNames(
                                    styles["mode-latest"],
                                    mode === "featured" && styles["is-active"]
                                )}
                                onClick={() => handleModeChange("featured")}
                            >
                                Featured
                            </div>
                            <div
                                className={classNames(
                                    styles["mode-latest"],
                                    mode === "latest" && styles["is-active"]
                                )}
                                onClick={() => handleModeChange("latest")}
                            >
                                Latest
                            </div>
                            <div
                                className={classNames(
                                    styles["mode-random"],
                                    mode === "random" && styles["is-active"]
                                )}
                                onClick={() => handleModeChange("random")}
                            >
                                Random <FontAwesomeIcon icon={faArrowRotateRight} />
                            </div>
                        </div>

                        <div className={styles["art-list"]}>
                            {list.map((item, index) => (
                                <div
                                    className={
                                        "token" in item ? styles["picture"] : styles["affiliate"]
                                    }
                                    key={index}
                                >
                                    {"token" in item ? (
                                        <Picture
                                            onClick={async () => {
                                                setIsCreatingProduct(true);

                                                setSelectedProductColorImageUrl(
                                                    `https://file.llamastories.com/art-${item.token}.png`
                                                );

                                                const data = await TeemillApiInstance.getProduct({
                                                    image_url: `https://file.llamastories.com/art-${item.token}.png`,
                                                    colours: selectedProductColorValue!,
                                                    item_code: selectedProductValue!
                                                });
                                                setProductLink(data.url);
                                                setIsCreatingProduct(false);
                                            }}
                                            discord_user={item.discord_user}
                                            token={item.token}
                                        />
                                    ) : (
                                        <>
                                            {/*<Affiliate
                                                name={item.name}
                                                asin={item.asin}
                                                showDisclaimer={false}
                                                country={country}
                                            />*/}
                                        </>
                                    )}
                                </div>
                            ))}
                        </div>
                    </div>
                )}
                {selectedProductColorImageUrl && (
                    <div className={styles["modal"]}>
                        <div className={styles["content"]}>
                            <div className={styles["body"]}>
                                <div className={styles["inner-body"]}>
                                    <div className={styles["message"]}>
                                        {isCreatingProduct ? (
                                            <>
                                                Creating product
                                                <FontAwesomeIcon icon={faSpinner} spin={true} />
                                            </>
                                        ) : (
                                            <>
                                                Your product is ready:{" "}
                                                <a
                                                    href={productLink}
                                                    target="_blank"
                                                    rel="noreferrer"
                                                >
                                                    Go to store
                                                </a>
                                            </>
                                        )}
                                    </div>

                                    <div
                                        className={styles["button"]}
                                        onClick={() => {
                                            setProductLink("");
                                            setSelectedProductColorImageUrl("");
                                        }}
                                    >
                                        Close
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </Page>
    );
};

export default STORE;
