import { post, TResult } from "./BaseApi";

export type TAuth = {
    isAuthenticated: boolean;
    token: string;
    admin: boolean;
    id: number;
};

class AuthAPI {
    public async SignIn(name: string, password: string): Promise<TAuth> {
        const signInResult = await post<TResult<TAuth>>(
            "auth/signin",
            {
                name,
                password
            },
            false,
            false
        );
        if (signInResult.status === 200) {
            const result = signInResult.data.result;

            const auth: TAuth = {
                token: result.token as string,
                isAuthenticated: signInResult.status === 200,
                id: result.id,
                admin: result.admin
            };

            return auth;
        } else {
            const auth: TAuth = {
                token: "",
                isAuthenticated: false,
                id: 0,
                admin: false
            };

            return auth;
        }
    }
}

export const Auth = new AuthAPI();
